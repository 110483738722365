import axios from 'axios';
import authHeader from './auth-header';

class ProductService {
    getProduct($productId) {
        return axios.get(process.env.VUE_APP_API_URL + 'product/'+$productId, { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }
    
    getProducts() {
        return axios.get(process.env.VUE_APP_API_URL + 'products/list', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }

    productCreate(productData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'product/create', //url
            productData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new ProductService();
