import axios from 'axios';
import authHeader from './auth-header';

class UnitService {
    getUnits() {
        return axios.get(process.env.VUE_APP_API_URL + 'units/list', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new UnitService();
