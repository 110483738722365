import axios from 'axios';
import authHeader from './auth-header';

class BrandService {
    getBrands() {
        return axios.get(process.env.VUE_APP_API_URL + 'brands/list', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }
    
    brandCreate(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'brand/create', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new BrandService();
