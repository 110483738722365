<template>
  <div class="col-md-12">
    <div class="card card-container">
      <form @submit.prevent="ProductCreate">
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input type="text" class="form-control" id="name" v-model="name" required="required" ref="name">
        </div>
        <div class="mb-3">
          <label for="unit_id">select a unit</label>
          <select v-model="unit_id" name="unit_id" class="form-select" required="required">
            <option v-for="(k, unit) in units.data" v-bind:key="unit" :value="k.id">{{ k.name }}</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="unit_amount" class="form-label">unit_amount</label>
          <input type="number" min="0" step="any" required="required" class="form-control" id="unit_amount" v-model="unit_amount">
        </div>
        <div class="mb-3">
          <label for="brand_id">select a brand</label>
          <select v-model="brand_id" name="brand_id" class="form-select" required="required">
            <option v-for="(k, brand) in brands.data" v-bind:key="brand" :value="k.id">{{ k.name }}</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary">Create Product</button>
    </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>
// import * as yup from "yup";
import ProductsService from "../services/product.service";
import UnitService from "../services/unit.service";
import BrandService from "../services/brand.service";

export default {
  name: "product create",
  methods: {
    ProductCreate() {
      console.log(this.name);
      this.formData = {
        'name': this.name,
        'unit_id': this.unit_id,
        'unit_amount': this.unit_amount,
        'brand_id': this.brand_id,
      };
      ProductsService.productCreate(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;
            this.formData = {};
            this.name = '';
            this.unit_id ='';
            this.unit_amount = '';
            this.brand_id = '';
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      formData: {},
      name: '',
      unit_id: '',
      units:'',
      brands:'',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }

    UnitService.getUnits().then(
      (response) => {
        this.units = response.data;
        console.log(this.units);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    BrandService.getBrands().then(
      (response) => {
        this.brands = response.data;
        console.log(this.brands);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );


  },
};

</script>

<style scoped>
</style>

